import aiCleaningRobot from "../assets/projects-photo/ai-cleaning-robot.png";
import backendProject from "../assets/projects-photo/backend-project.png";
import tobetoPlatform from "../assets/projects-photo/tobeto-platform.png";
import eBookStore from "../assets/projects-photo/ebook-store.png";
import hotelProject from "../assets/projects-photo/hotelier-api-consume-project.png";
import signalrRestaurant from "../assets/projects-photo/signalr-restaurant.png";

const projectsData = [
  {
    id: 1,
    title: "SignalR Restaurant",
    description: "Full Stack Developed - SignalR Restaurant Web Application",
    image: signalrRestaurant,
    githubUrl: "https://github.com/atacanguzelkaya/SignalRRestaurant",
    viewUrl: "",
    details: "Real-time restaurant management system built with ASP.NET Core MVC, using N-tier architecture and SOLID principles. MSSQL, Entity Framework Core, and LINQ are used for data processing. SignalR enables notifications, messaging, and reservations. UI designed with Bootstrap, Ajax, and JavaScript; QR code for ordering."
  },
  {
    id: 2,
    title: "Hotelier Api Consume Project",
    description: "Full Stack Developed - Hotel Management Web Application",
    image: hotelProject,
    githubUrl: "https://github.com/atacanguzelkaya/HotelProject",
    viewUrl: "",
    details: "Hotel Project is an ASP.NET Core 8 and MVC online hotel reservation system with a robust backend using Repository and Service Patterns, Entity Framework Core for data processing, Automapper for mapping and a modern user interface with Bootstrap and JQuery. It supports easy to use administration system with admin panel and uses MSSQL for database management."
  },
  {
    id: 3,
    title: "Twenty Mini Project",
    description: "Various Technologies and Frameworks - Management Applications",
    image: backendProject,
    githubUrl: "https://github.com/atacanguzelkaya/TwentyMiniProjects",
    viewUrl: "",
    details: "There are projects developed with C# using various technologies and frameworks. ADO.NET, Windows Form, ASP.NET Core API, RapidAPI, Kaggle Datasets, Entity Framework, Dapper, MongoDB, PostgreSQL and many other technologies."
  },
  {
    id: 4,
    title: "E-Book Store",
    description: "Full Stack Developed - Store Management Web Application",
    image: eBookStore,
    githubUrl: "https://github.com/atacanguzelkaya/EBookStore",
    viewUrl: "",
    details: "Ebook Store is an ASP.NET Core 8 and MVC online bookstore with a robust backend using Repository and Service Patterns, Entity Framework Core for data handling, Automapper for mapping, and a modern UI with Bootstrap and JQuery. It supports Individual Account authentication and uses MSSQL for database management."
  },
  {
    id: 5,
    title: "Rent A Car",
    description: "Backend Developed - Rental Management Web Application",
    image: backendProject,
    githubUrl: "https://github.com/atacanguzelkaya/CarRental",
    viewUrl: "",
    details: "This project involves the development of a backend system for managing rental vehicles, including features such as user authentication, vehicle lists and reservation management. The project includes Clean Architecture, CQRS, Advanced Repository, Dynamic Querying, JWT, OTP, Role-Based Management, Distributed Caching (Redis), Logging (Serilog)."
  },
  {
    id: 6,
    title: "Tobeto Platform",
    description: "Full Stack Developed - Learning Management System",
    image: tobetoPlatform,
    githubUrl: "https://github.com/atacanguzelkaya/TobetoPlatformFePair3",
    viewUrl: "",
    details: "This project involves the development of a backend system for managing rental vehicles, including features such as user authentication, vehicle lists and reservation management. The project includes Clean Architecture, CQRS, Advanced Repository, Dynamic Querying, JWT, OTP, Role-Based Management, Autofac, Logging (Serilog) on the backend side. On the frontend side, React, Typescript, Axios, Redux/Toolkit, Bootstrap were used."
  },
  {
    id: 7,
    title: "AI Cleaning Robot",
    description: "Software developed - AI Control System",
    image: aiCleaningRobot,
    githubUrl: "https://github.com/atacanguzelkaya/Smart_Vacuum_Bot_Simulation",
    viewUrl: "",
    details: "It is an artificial intelligence-controlled cleaning robot project that can navigate and clean in various environments. ROS (Robot Operating System) software was used in the Ubuntu operating system to control the robots. Tests were conducted in Gazebo and Rviz simulation environments. An artificial intelligence robot was created using ROS software in its physical form using Raspberry Pi 4 (Pi OS), Lidar, stepping motors."
  },
];

export default projectsData;